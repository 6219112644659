import { createRouter, createWebHistory } from 'vue-router'

import HomeView from '@/views/HomeView.vue'
import LoginView from '@/views/LoginView.vue'
import ProfileView from '@/views/ProfileView.vue'
import RegisterView from '@/views/RegisterView.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    meta: { title: 'Euroburz | Home' },
    component: HomeView
  },
  {
    path: '/login',
    name: 'login',
    meta: { title: 'Euroburz | Login' },
    component: LoginView
  },
  {
    path: '/register',
    name: 'register',
    meta: { title: 'Euroburz | Register' },
    component: RegisterView
  },
  {
    path: '/profile',
    name: 'profile',
    meta: { title: 'Euroburz | Profile' },
    component: ProfileView
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  console.log(to);
  document.title = to.meta.title;
  next();
});


export default router;
