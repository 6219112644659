<template>
    <section>
        <div class="box"></div>
        <h1>PAYMENT</h1>
        <p>By selecting a payment plan and completing the required payment, your documents, CV, and employment
            preferences for working in Croatia will be made visible to employers across the country. </p>
        <div class="point-list">
            <div class="point">
                <h2>BASIC PLAN</h2>
                <div>
                    <span class="price">29€</span>
                    <span class="details">/per person</span>
                </div>
                <p>By choosing this plan your profile will be displayed to employers for 1 month.</p>
                <RouterLink class="button" to="/register">JOIN US</RouterLink>
            </div>
            <div class="point">
                <h2>PRO PLAN</h2>
                <div>
                    <span class="price">73€</span>
                    <span class="details">/per person</span>
                </div>
                <p>By choosing this plan your profile will be displayed to employers for 3 months.</p>
                <RouterLink class="button" to="/register">JOIN US</RouterLink>
            </div>
            <div class="point">
                <h2>PREMIUM PLAN</h2>
                <div>
                    <span class="price">159€</span>
                    <span class="details">/per person</span>
                </div>
                <p>By choosing this plan your profile will be displayed to employers for 6 months.</p>
                <RouterLink class="button" to="/register">JOIN US</RouterLink>
            </div>
        </div>
    </section>
</template>

<style scoped>
section {
    padding-block: var(--m-0);

}

.box {
    width: 30px;
    height: 30px;
    background-color: var(--c-accent);
    margin-right: auto;
    margin-left: var(--m-side);
}

section h1 {
    font-size: var(--fs-600);
    color: var(--c-secondary);
    font-family: var(--font-primary);
    font-weight: 800;
    margin-top: var(--m-1);
    padding-inline: var(--m-side);
    text-align: center;
}

section p {
    font-size: var(--fs-300);
    color: var(--c-secondary);
    font-family: var(--font-primary);
    font-weight: 400;
    margin-top: var(--m-1);
    padding-inline: var(--m-side);
}

.point-list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: var(--m-0);
    margin-block: var(--m-xl);
}

.point {
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: center;
    gap: var(--m-3);
    border-radius: 50px;
    padding: 3rem;
    margin-inline: var(--m-side);
    background-color: var(--c-primary);
}

.point h2 {
    font-size: var(--fs-400);
    color: var(--c-secondary);
    font-family: var(--font-primary);
    font-weight: 800;
}

.price {
    font-size: 4rem;
    color: var(--c-secondary);
    font-family: var(--font-primary);
    font-weight: 900;
}

.details {
    font-size: var(--fs-300);
    color: var(--c-sub);
    font-family: var(--font-primary);
    font-weight: 300;
    margin-left: var(--m-3);
}

.point p {
    font-size: var(--fs-300);
    color: var(--c-secondary);
    font-family: var(--font-primary);
    font-weight: 400;
    padding-inline: 0;
}


.button {
    position: relative;
    text-decoration: none;
    cursor: pointer;
    z-index: 1;
    align-self: center;

    padding: 15px 25px;
    border: unset;
    border-radius: 50px;
    background-color: var(--c-accent);
    color: var(--c-primary);
    font-family: var(--font-primary);
    font-weight: 700;
    font-size: var(--fs-300);

    margin-top: var(--m-0);

    transition: all 250ms;
}

.button::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 0;
    border-radius: 50px;
    background-color: var(--c-secondary);
    z-index: -2;
    transition: all 250ms;
}

.button:hover::before {
    width: 100%;
}

@media (min-width: 1024px) {
    .point-list {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        gap: var(--m-0);
        padding-inline: var(--m-side);
    }

    .point {
        margin-inline: 0;
    }
}
</style>