<template>
    <BackgroundVideo />
    <div class="login-container">
        <div class="login-form">
            <h2>Welcome back.</h2>
            <p>Enter your details.</p>
            <form @submit.prevent="handleSubmit">
                <div class="form-group">
                    <input type="text" id="email" v-model="email" placeholder="Email" required />
                    <img src="../assets/images/person.svg" alt="" class="icon">
                    <span v-if="errors.email" class="error">{{ errors.email }}</span>
                </div>
                <div class="form-group">
                    <input type="password" id="password" v-model="password" placeholder="Password" required />
                    <img src="../assets/images/lock.svg" alt="" class="icon">
                    <span v-if="errors.password" class="error">{{ errors.password }}</span>
                </div>
                <p class="password-text">Forgot password?</p>
                <button type="submit">LOGIN</button>
            </form>
            <RouterLink to="/register">
                <p class="account-text">Don't have an account?</p>
            </RouterLink>
        </div>
    </div>
</template>

<script setup>
import BackgroundVideo from '@/components/BackgroundVideo.vue';
import { ref } from 'vue';

const email = ref('');
const password = ref('');
const errors = ref({ email: '', password: '' });

const validateForm = () => {
    let valid = true;
    errors.value = { email: '', password: '' };

    if (!email.value) {
        errors.value.email = 'Email is required';
        valid = false;
    }

    if (!password.value) {
        errors.value.password = 'Password is required';
        valid = false;
    }

    return valid;
};

const handleSubmit = () => {
    if (validateForm()) {
        console.log('Email:', email.value);
        console.log('Password:', password.value);
    }
};
</script>

<style scoped>
.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.login-form {
    background-color: var(--c-primary);
    padding-inline: var(--m-0);
    padding-block: var(--m-0);
    border-radius: 5rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    max-width: 300px;
    min-width: 100px;
    width: 60%;
}

form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

h2 {
    font-family: var(--font-primary);
    font-size: var(--fs-700);
    font-weight: 900;
    color: var(--c-secondary);
    text-align: center;
    margin-top: var(--m-0);
}

p {
    font-family: var(--font-primary);
    font-size: var(--fs-300);
    font-weight: 400;
    color: var(--c-sub);
    text-align: center;
    margin-bottom: var(--m-0);
}

.form-group {
    position: relative;
    margin-bottom: var(--m-2);
    width: 95%;
}

.icon {
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
    width: 25px;
    height: 20px;
    fill: var(--c-secondary);
}

input {
    width: 100%;
    height: 40px;
    padding: 1rem;
    box-sizing: border-box;
    border: 2px solid var(--c-secondary);
    border-radius: 3rem
}

button {
    cursor: pointer;
    margin-top: var(--m-0);
    padding: 15px 25px;
    border: unset;
    border-radius: 50px;
    color: var(--c-primary);
    z-index: 1;
    background: var(--c-secondary);
    position: relative;
    font-family: var(--font-primary);
    font-weight: 700;
    font-size: var(--fs-300);
    transition: all 250ms;
    overflow: hidden;
}

button::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 0;
    border-radius: 50px;
    background-color: var(--c-accent);
    z-index: -1;
    transition: all 250ms
}

button:hover::before {
    width: 100%;
}

.error {
    color: red;
    font-size: 0.875em;
}

.account-text,
.password-text {
    font-family: var(--font-primary);
    font-size: var(--fs-200);
    font-weight: 200;
    color: var(--c-sub);
    text-align: center;
    margin-top: var(--m-2);
    text-decoration: none;
}

.password-text {
    align-self: flex-end;
    margin-top: -5px;
    margin-right: 10px;
}

.account-text:hover,
.password-text:hover {
    cursor: pointer;
    color: var(--c-secondary);
    user-select: none;
}

a {
    text-decoration: none;
}


@media (min-width: 1024px) {
    .login-form {
        padding-block: var(--m-3);
    }

    h2 {
        font-size: var(--fs-500);
    }
}
</style>