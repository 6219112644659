<template>
    <section>
        <div class="box"></div>
        <h1>ABOUT US</h1>
        <p>We connect job seekers directly with employers. </p>
        <p>With a monthly subscription, your profile is displayed to companies actively hiring, increasing your
            visibility and chances of being contacted.</p>

        <div class="point-list">
            <div class="point">
                <h2>Choose a Job</h2>
                <p>Select the type of job you're interested in.</p>
            </div>
            <div class="point">
                <h2>Fill Out Your Info</h2>
                <p>Complete your personal and professional details to create your profile.</p>
            </div>
            <div class="point">
                <h2>Subscribe & Wait</h2>
                <p>Pay the monthly fee and let employers reach out to you.</p>
            </div>
        </div>
        <div class="imgbgr">
            <img src="../../assets/images/sky.jpg" alt="Image of an airplane">
        </div>
    </section>
</template>

<style scoped>
section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: var(--m-xl);
    padding-top: var(--m-0);
    background-color: var(--c-primary);
    color: var(--c-white);
}

.box {
    width: 30px;
    height: 30px;
    background-color: var(--c-accent);
    margin-right: auto;
    margin-left: var(--m-side);
}

section h1 {
    font-size: var(--fs-600);
    color: var(--c-secondary);
    font-family: var(--font-primary);
    font-weight: 800;
    margin-top: var(--m-1);
    padding-inline: var(--m-side);
}

section p {
    font-size: var(--fs-300);
    color: var(--c-secondary);
    font-family: var(--font-primary);
    font-weight: 400;
    margin-top: var(--m-1);
    padding-inline: var(--m-side);
}

.point {
    padding: var(--m-0);
    background-color: var(--c-secondary);
    border-radius: 0 50px 50px 0;
    margin-top: 4rem;
    height: 5rem;
    width: 70%;
    align-self: flex-start;
}

.point h2 {
    font-size: var(--fs-400);
    font-family: var(--font-primary);
    font-weight: 800;
    color: var(--c-primary);
    text-align: right;
}

.point p {
    font-size: var(--fs-300);
    font-family: var(--font-primary);
    font-weight: 400;
    color: var(--c-primary);
    text-align: right;
    padding-inline: 0;
}

img {
    width: 100%;
    height: auto;
    max-width: 500px;
    margin-top: var(--m-0);

}



@media (min-width: 1024px) {
    .point-list {
        display: flex;
        justify-content: space-between;
        gap: var(--m-0);
        margin-inline: var(--m-side);
    }

    .point {
        padding: var(--m-1);
        background-color: var(--c-secondary);
        border-radius: 50px 50px 0 0;
        margin-top: 4rem;
        height: 10rem;
        width: 25%;
    }

    .point h2,
    .point p {
        text-align: left;
    }

    img {
        margin-top: 0;
    }

    .imgbgr {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        background-color: #005FA4;
    }
}
</style>