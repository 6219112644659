<template>
    <div>
        <div class="carousel-container" ref="carousel">
            <div class="carousel-wrapper" ref="wrapper">
                <div v-for="(job, index) in jobs" :key="index" :class="{ 'selected': selectedIndex === index }"
                    class="carousel-item-wrapper" @click="selectItem(index)">
                    <CarouselItem :title="job.title" :image="job.image" />
                </div>
            </div>
        </div>

        <div class="selected-description"> {{ selectedJob.description }}</div>
        <RouterLink to="/register">
            <div class="button">JOIN US</div>
        </RouterLink>
    </div>
</template>

<script setup>
import { ref, watch, onMounted, nextTick } from 'vue'
import CarouselItem from './CarouselItem.vue'
import jobs from '../assets/data/jobs.json'

const selectedIndex = ref(0)
const selectedJob = ref(jobs[selectedIndex.value])

const carousel = ref(null)
const wrapper = ref(null)

const selectItem = (index) => {
    selectedIndex.value = index
    selectedJob.value = jobs[index]
}

const scrollToCenter = (index) => {
    const itemElement = wrapper.value.children[index]
    const carouselRect = carousel.value.getBoundingClientRect()
    const itemRect = itemElement.getBoundingClientRect()

    const offset = itemRect.left - (carouselRect.left + (carouselRect.width / 2) - (itemRect.width / 2))

    carousel.value.scrollBy({ left: offset, behavior: 'smooth' })
}

watch(selectedIndex, (newIndex) => {
    nextTick(() => {
        scrollToCenter(newIndex)
    })
})

onMounted(() => {
    scrollToCenter(selectedIndex.value)
})
</script>

<style scoped>
.carousel-container {
    display: flex;
    overflow-x: auto;
    scroll-behavior: smooth;
    align-items: center;
    width: 100%;
    margin: 0 auto;
    margin-top: var(--m-1);
}

.carousel-wrapper {
    display: flex;
    padding: 20px 0;
}

.carousel-item-wrapper {
    flex: 0 0 auto;
    padding: 10px;
    cursor: pointer;
    transition: all 0.3s ease-in;
}

.carousel-item-wrapper.selected {
    transform: scale(1.1);
    border-radius: 12px;
    transition: all 0.3s ease-out;
}

.selected-description {
    text-align: center;
    margin-top: var(--m-0);
    font-size: var(--fs-350);
    font-family: var(--font-primary);
    color: var(--c-secondary);
    padding-inline: var(--m-side);
}

.button {
    position: relative;
    text-decoration: none;
    cursor: pointer;
    z-index: 1;
    max-width: fit-content;
    margin-left: auto;
    margin-right: auto;

    padding: 15px 25px;
    border: unset;
    border-radius: 50px;
    background-color: var(--c-accent);
    color: var(--c-primary);
    font-family: var(--font-primary);
    font-weight: 700;
    font-size: var(--fs-300);

    margin-top: var(--m-0);

    transition: all 250ms;
}

.button::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 0;
    border-radius: 50px;
    background-color: var(--c-secondary);
    z-index: -2;
    transition: all 250ms;
}

.button:hover::before {
    width: 100%;
}


.carousel-container::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    margin: var(--m-side);
}

.carousel-container::-webkit-scrollbar {
    width: 10px;
}

.carousel-container::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, .3);
    background-color: var(--c-sub);
}

a {
    text-decoration: none;
}

@media (min-width: 1024px) {
    .carousel-container {
        margin-inline: var(--m-side);
        width: calc(100% - var(--m-side) * 2);
        height: 450px;
    }

    .carousel-item-wrapper.selected {
        transform: scale(1.5);
        transition: all 0.3s ease-out;
        z-index: 25;
    }

    .carousel-container::-webkit-scrollbar-track {
        margin: 0;
    }
}
</style>