<template>
    <footer>
        <div class="items-list">
            <div class="item">
                <div class="title">Email:</div>
                <div class="text">support@euroburz.com</div>
            </div>
            <div class="item">
                <div class="title">Phone:</div>
                <div class="text">+385 091-721/3223</div>
            </div>
            <div class="item">
                <div class="title">Address:</div>
                <div class="text"> Ul. Josipa Milakovića, 10430,Samobor, Croatia</div>
            </div>
        </div>
        <div class="socials">
            <img src="../../assets/images/whatsapp.svg" alt="Whatsapp icon">
            <img src="../../assets/images/instagram.svg" alt="Instagram icon">
            <img src="../../assets/images/facebook.svg" alt="Facebook icon">
        </div>
        <h1>EUROBURZ</h1>
        <p>Euroburz.com is a property of CARRIERA START ltd. ©2024 All Rights Reserved.</p>
    </footer>
</template>

<style scoped>
footer {
    padding-block: 0.5rem;
    background-color: var(--c-secondary);
    padding-inline: var(--m-1);

}

.items-list {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.item {
    display: flex;
    justify-content: space-between;
    gap: var(--m-0);
}

.title {
    font-size: var(--fs-300);
    color: var(--c-primary);
    font-family: var(--font-primary);
    font-weight: 700;
    margin-top: var(--m-1);
}

.text {
    font-size: var(--fs-300);
    color: var(--c-primary);
    font-family: var(--font-primary);
    font-weight: 200;
    margin-top: var(--m-1);
    text-align: right;
}

h1 {
    font-size: var(--fs-xl);
    color: var(--c-primary);
    font-family: var(--font-primary);
    font-weight: 800;
    margin-top: var(--m-1);
    text-align: center;
}

p {
    font-size: var(--fs-100);
    color: var(--c-primary);
    font-family: var(--font-primary);
    font-weight: 100;
    text-align: center;
}

.socials {
    display: flex;
    gap: var(--m-1);
    margin-bottom: -0.5rem;
    margin-top: var(--m-0);
}

img {
    max-width: 75px;
    width: 2rem;
    fill: var(--c-primary);
}

@media (min-width: 1024px) {

    p,
    h1 {
        text-align: left;
    }

    img {
        width: 2.5rem;
    }

    .socials {
        margin-bottom: -1.5rem;
    }

    footer {
        padding-inline: var(--m-1);
    }
}
</style>