<template>
    <video autoplay muted loop>
        <source src="../assets/videos/bgr.mp4" type="video/mp4">
    </video>
</template>

<style scoped>
video {
    width: 100vw;
    height: 100vh;
    object-fit: cover;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: -1;
}
</style>