<template>
    <BackgroundVideo />
    <div class="login-container">
        <div class="login-form">
            <h2>{{ (step !== 4) ? "Create account." : "Account created." }}</h2>
            <p> {{ stepInfo[step - 1] }}</p>
            <ProgessBar class="progress-bar" :step="step" />
            <form @submit.prevent="handleSubmit">
                <div class="login-step" v-if="step === 1">
                    <div class="form-row form-group">
                        <input type="text" id="email" v-model="email" placeholder="Email" required />
                        <img src="../assets/images/person.svg" alt="" class="icon">
                    </div>
                    <div class="form-row form-group">
                        <input type="password" id="password" v-model="password" placeholder="Password" required />
                        <img src="../assets/images/lock.svg" alt="" class="icon">
                    </div>
                    <div class="form-row form-group">
                        <input type="password" id="repeatPassword" v-model="repeatPassword"
                            placeholder="Repeat password" required />
                        <img src="../assets/images/lock.svg" alt="" class="icon">
                    </div>
                    <div class="button-row">
                        <button @click="() => step++">NEXT</button>
                    </div>
                </div>

                <div class="login-step" v-if="step === 2">
                    <div class="form-row">
                        <div class="form-group">
                            <input type="text" id="name" v-model="name" placeholder="Name" required />
                            <img src="../assets/images/person.svg" alt="" class="icon">
                        </div>
                        <div class="form-group">
                            <input type="text" id="surname" v-model="surname" placeholder="Surname" required />
                            <img src="../assets/images/person.svg" alt="" class="icon">
                        </div>
                    </div>
                    <div class="form-row form-group">
                        <input name="date" type="text" placeholder="Date of birth" onfocus="(this.type='date')"
                            onblur="if(!this.value)this.type='text'" required>
                    </div>
                    <div class="form-row">
                        <select id="nationality" name="nationality">
                            <option class="placeholder" value="" selected disabled hidden>Nationality</option>
                            <option v-for="nationality in nationalities" :key="nationality" :value="nationality">
                                {{ nationality }}
                            </option>
                        </select>
                        <select id="country" name="country">
                            <option class="placeholder" value="" selected disabled hidden>Country</option>
                            <option v-for="country in countries" :key="country" :value="country">
                                {{ country }}
                            </option>
                        </select>
                    </div>
                    <div class="form-row" style="width: 49%; align-self: start;">
                        <select id="gender" name="gender">
                            <option class="placeholder" value="" selected disabled hidden>Gender</option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                            <option value="Other">Other</option>
                        </select>
                    </div>
                    <div class="button-row">
                        <button @click="() => step--">BACK</button>
                        <button @click="() => step++">NEXT</button>
                    </div>
                </div>

                <div class="login-step" v-if="step === 3">

                    <div class="form-row">
                        <select id="academicDegree" name="academicDegree">
                            <option class="placeholder" value="" selected disabled hidden>Academic degree</option>
                            <option value="None">No education</option>
                            <option value="Primary">Primary education</option>
                            <option value="Secondary">Secondary eduaction</option>
                            <option value="University">University education</option>
                        </select>
                    </div>
                    <div class="form-row">
                        <select id="job" name="job">
                            <option class="placeholder" value="" selected disabled hidden>Job</option>
                            <option v-for="job in jobs" :key="job.title" :value="job.title">
                                {{ job.title }}
                            </option>

                        </select>
                    </div>
                    <div class="form-row">
                        <textarea placeholder="Describe yourself in a few words."></textarea>
                    </div>


                    <div class="button-row">
                        <button @click="() => step--">BACK</button>
                        <button @click="() => step++">FINISH</button>
                    </div>
                </div>

                <div class="login-step" v-if="step === 4">
                    <p>Thank you for joining us! Your account has been successfully created. You can now log in and
                        choose your plan.</p>
                    <div class="button-row">
                        <RouterLink to="/profile"><button>PROFILE</button></RouterLink>
                    </div>

                </div>
            </form>
            <RouterLink v-if="step !== 4" to="/login">
                <p class="account-text">Already have an account?</p>
            </RouterLink>

        </div>
    </div>
</template>

<script setup>
import BackgroundVideo from '@/components/BackgroundVideo.vue';
import ProgessBar from '@/components/ProgessBar.vue';
import { ref } from 'vue';

import nationalitiesData from '@/assets/data/nationalities.json';
import countriesData from '@/assets/data/countries.json';
import jobsData from '@/assets/data/jobs.json';

const nationalities = ref(nationalitiesData);
const countries = ref(countriesData);
const jobs = ref(jobsData);

const step = ref(1);
let stepInfo = ['Account details.', 'Personal details.', 'Proffesion details.', ''];

const email = ref('');
const password = ref('');
const repeatPassword = ref('');

</script>

<style scoped>
.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.login-form {
    background-color: var(--c-primary);
    padding-inline: var(--m-0);
    padding-block: var(--m-0);
    border-radius: 5rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    max-width: 300px;
    min-width: 100px;
    width: 60%;
    height: 550px;
}

.login-step {
    display: flex;
    flex-direction: column;
    width: 95%;
}

form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

h2 {
    font-family: var(--font-primary);
    font-size: var(--fs-700);
    font-weight: 900;
    color: var(--c-secondary);
    text-align: center;
    margin-top: var(--m-0);
}

p {
    font-family: var(--font-primary);
    font-size: var(--fs-300);
    font-weight: 400;
    color: var(--c-sub);
    text-align: center;
    margin-bottom: var(--m-2);
}

.progress-bar {
    margin-bottom: var(--m-1);
}

.form-group {
    position: relative;
    width: 100%;
}

.form-row {
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: var(--m-2);
    margin-bottom: var(--m-2);

}

.button-row {
    display: flex;
    justify-content: center;
    width: 100%;
    gap: var(--m-2);
}

.icon {
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
    width: 25px;
    height: 20px;
    fill: var(--c-secondary);
}

input,
select {
    width: 100%;
    height: 40px;
    padding: 1rem;
    box-sizing: border-box;
    border: 2px solid var(--c-secondary);
    border-radius: 3rem
}

select {
    padding: 0%;
    padding-inline: 1rem;
}

textarea {
    width: 100%;
    height: 150px;
    padding: 1rem;
    box-sizing: border-box;
    border: 2px solid var(--c-secondary);
    border-radius: 2rem;
    resize: none;
}

input::placeholder,
textarea::placeholder {
    font-family: var(--font-primary);
    font-size: var(--fs-200);
    font-weight: 300;
    color: var(--c-sub);
}

button {
    cursor: pointer;
    margin-top: var(--m-0);
    padding: 15px 25px;
    border: unset;
    border-radius: 50px;
    color: var(--c-primary);
    z-index: 1;
    background: var(--c-secondary);
    position: relative;
    font-family: var(--font-primary);
    font-weight: 700;
    font-size: var(--fs-300);
    transition: all 250ms;
    overflow: hidden;
}

button::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 0;
    border-radius: 50px;
    background-color: var(--c-accent);
    z-index: -1;
    transition: all 250ms
}

button:hover::before {
    width: 100%;
}

.error {
    color: red;
    font-size: 0.875em;
}

.account-text {
    font-family: var(--font-primary);
    font-size: var(--fs-200);
    font-weight: 200;
    color: var(--c-sub);
    text-align: center;
    margin-top: var(--m-2);
}

.account-text:hover {
    cursor: pointer;
    color: var(--c-secondary);
    user-select: none;
}

a {
    text-decoration: none;
}


@media (min-width: 1024px) {
    .login-form {
        padding-block: var(--m-3);
        height: 800px;
    }

    h2 {
        font-size: var(--fs-500);
    }
}
</style>