<template>
    <nav>
        <div v-if="checkIfMobile()" class="nav-s">
            <img src="../../assets/images/hamburgerIcon.svg" alt="Hamburger Icon" @click="toggleSidebar" />
            <Logo />

            <div class="sidebar" v-if="isSidebarOpen">
                <Logo :dark-mode="true" />
                <a href="#about" @click="toggleSidebar" class="button">About</a>
                <a href="#jobs" @click="toggleSidebar" class="button">Jobs</a>
                <a href="#payment" @click="toggleSidebar" class="button">Payment</a>
                <a href="#contact" @click="toggleSidebar" class="button">Contact</a>
                <div class="sidebar-bottom">
                    <RouterLink class="button" @click="toggleSidebar" to="/login">Login</RouterLink>
                    <RouterLink class="button" @click="toggleSidebar" to="/register">Register</RouterLink>
                </div>
            </div>
            <div v-if="isSidebarOpen" class="overlay" @click="toggleSidebar"></div>
        </div>
        <div v-else class="nav-l">
            <Logo />
            <a href="#about" class="button">About</a>
            <a href="#jobs" class="button">Jobs</a>
            <a href="#payment" class="button">Payment</a>
            <a href="#contact" class="button">Contact</a>
            <RouterLink class="button" to="/login">Login</RouterLink>
            <RouterLink class="button" to="/register">Register</RouterLink>
        </div>
    </nav>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from 'vue';
import Logo from '../Logo.vue';

const isMobile = ref(false);
const isSidebarOpen = ref(false);

function checkIfMobile() {
    return isMobile.value;
}

function toggleSidebar() {
    isSidebarOpen.value = !isSidebarOpen.value;
}

let mql;

function handleMqlChange(e) {
    isMobile.value = e.matches;
}

onMounted(() => {
    mql = window.matchMedia('(max-width: 1024px)');
    isMobile.value = mql.matches;
    mql.addEventListener('change', handleMqlChange);
});

onUnmounted(() => {
    mql.removeEventListener('change', handleMqlChange);
});
</script>

<style scoped>
nav {
    margin-top: var(--m-nav);
    margin-inline: var(--m-side);
}

img {
    width: 50px;
    height: 50px;
}

.logo {
    width: 70px;
    height: 70px;
}

.nav-s {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.nav-l {
    display: flex;
    justify-content: start;
    align-items: center;
    gap: var(--m-2);
    width: 100%;
    margin-right: var(--m-1);
}

.nav-l a:nth-child(1) {
    margin-right: var(--m-0);
}

.nav-l a:nth-last-child(2) {
    margin-left: auto;
}

.button {
    position: relative;
    text-decoration: none;
    cursor: pointer;
    z-index: 1;

    padding: 15px 25px;
    border: unset;
    border-radius: 25px;
    background-color: transparent;
    color: var(--c-primary);
    font-family: var(--font-primary);
    font-weight: 700;
    font-size: var(--fs-300);

    transition: all 250ms;
}

.button::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 0;
    border-radius: 25px;
    background-color: var(--c-primary);
    z-index: -2;
    transition: all 250ms;
}

.button:hover {
    color: var(--c-secondary);
}

.button:hover::before {
    width: 100%;
}

.sidebar {
    position: fixed;
    top: 0;
    left: 0;
    width: 250px;
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;

    background-color: var(--c-primary);

    padding-inline: var(--m-1);
    transition: left 0.3s ease;
    z-index: 1001;
}

.sidebar-open {
    left: 0;
}

.sidebar .logo {
    margin-top: var(--m-xl);
    margin-bottom: var(--m-0);
    width: 100%;
    margin-left: 13rem;
}

.sidebar .button {
    color: var(--c-secondary);
}

.sidebar .button::before {
    background-color: var(--c-secondary);
}

.sidebar .button:hover {
    color: white;
}

.sidebar-bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: var(--m-xl);
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
}
</style>